import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'

import 'dayjs/locale/nl'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.locale('nl')

export { dayjs }
