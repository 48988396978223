import { ToiletSlotIcon } from '/components/ToiletSlotIcon'
import { ContainerLg } from '/components/Container'
import { useFirebaseApp } from '/machinery/firebase'
import { getDatabase, ref, onValue, off } from 'firebase/database'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { dayjs } from '/machinery/dayjs'

import styles from './Home.css'

export function Home() {
  const [toiletData, setToiletData] = React.useState([])
  const [fullExperience, setFullExperience] = React.useState(false)
  const app = useFirebaseApp()

  React.useEffect(
    () => {
      if (!app) return

      const database = getDatabase(app)
      const databaseRef = ref(database, `unit`)
      onValue(databaseRef, (snapshot) => {
        const data = snapshot.val()
        setToiletData(data.filter(Boolean))
      })

      return () => {
        off(databaseRef)
      }
    },
    [app]
  )

  return (
    <div className={styles.page}>
      <button className={styles.soundButton} onClick={_ => setFullExperience(prevValue => !prevValue)}>
        {fullExperience
          ? <span role='img' aria-label='geluid aan'>🔔</span>
          : <span role='img' aria-label='geluid uit'>🔕</span>
        }
      </button>

      <ContainerLg layoutClassName={styles.sectionLayout}>
        <div className={styles.introduction}>
          <h1 className={styles.title}>Kan ik al naar het <span role='img' aria-label='toilet'>🚽</span>?</h1>
          <p className={styles.description}>Ben jij het beu om steeds naar de toiletten te lopen en dan voor een gesloten deur te staan? Geen zorgen, wij bieden realtime informatie over welke toiletten beschikbaar en welke bezet zijn. Zo hoef je niet langer onnodig te wachten en kan je snel weer verder met je werk.</p>
        </div>
      </ContainerLg>

      <ContainerLg layoutClassName={styles.sectionLayout}>
        <div className={styles.main}>
          {toiletData && toiletData.map(({ id, available, label, timestamp }) => <Toilet key={id} {...{ id, available, label, timestamp, fullExperience }} />)}
        </div>
      </ContainerLg>
    </div>
  )
}

function Toilet({ available, label, timestamp, fullExperience }) {
  const isMounted = useRenderOnMount()
  const [showTimer, setShowTimer] = React.useState(false)

  return (
    <div className={styles.componentToilet}>
      <div className={styles.toiletIcon}>
        <ToiletSlotIcon isInUse={!Boolean(available)} withSound={fullExperience} />
      </div>
      <button className={styles.toiletLabel} type="button" onClick={handleOnClick}>{label}</button>
      {(isMounted && showTimer) && <ToiletTimer {...{ timestamp }} />}
    </div>
  )

  function handleOnClick() {
    setShowTimer(prevValue => !prevValue)
  }
}

function ToiletTimer({ timestamp }) {
  const currentTimerValue = useTimestampValueFromNow(timestamp)

  return (
    <div>{currentTimerValue}</div>
  )
}

function useTimestampValueFromNow(timestamp) {
  const [value, setValue] = React.useState('')

  React.useEffect(
    () => {
      const timerId = setInterval(() => tick(), 1000)
      tick()

      return () => {
        clearInterval(timerId)
      }
    },
    [timestamp]
  )

  function tick() {
    setValue(dayjs(timestamp).fromNow(true))
  }

  return value
}
